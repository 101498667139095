import { ClerkApp } from "@clerk/remix";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { Toaster } from "@kubrick/ui/components/ui/sonner";
import styles from "@kubrick/ui/global-app.css?url";
import Headers from "@mjackson/headers";
import {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunctionArgs,
  useLoaderData,
} from "@remix-run/react";
import { APIProvider } from "@vis.gl/react-google-maps";

import {
  SidebarInset,
  SidebarProvider,
} from "@kubrick/ui/components/ui/sidebar";

import { ErrorBoundaryScreen } from "./components/ErrorBoundary";
import { SidebarApp } from "./components/SidebarApp";
import { authPaths } from "./constants/auth";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];
export const meta: MetaFunction = () => {
  return [
    { title: "the guru" },
    {
      name: "description",
      content: "Welcome to The Guru!",
    },
  ];
};

export async function loader(args: LoaderFunctionArgs) {
  return rootAuthLoader(args, async ({ request, context }) => {
    const headers = new Headers(request.headers);
    const sidebarState = headers.cookie.get("sidebar:state");

    return {
      authenticated: !!request.auth.userId,
      isAuthPath: authPaths.includes(new URL(request.url).pathname),
      config: request.auth.userId
        ? {
            googleMapsApiKey: context.cloudflare.env.GOOGLE_API_KEY,
          }
        : null,
      sidebarDefaultOpen: sidebarState !== "true",
    };
  });
}

/**
 * root loader doesn't fire when users sign in or out
 * check if the user is navigating to/from an auth route and
 * ensure that the root loader fires so that we can check if they're
 * authenticated rather than relying on clerks component boundaries so that
 * we're server validating all requests
 */
export function shouldRevalidate({
  currentUrl,
  nextUrl,
  defaultShouldRevalidate,
}: ShouldRevalidateFunctionArgs) {
  if (
    authPaths.some(
      (path) =>
        currentUrl.pathname.includes(path) || nextUrl.pathname.includes(path),
    )
  ) {
    return true;
  }

  return defaultShouldRevalidate;
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <Toaster position="bottom-center" />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  const { authenticated, isAuthPath, config, sidebarDefaultOpen } =
    useLoaderData<typeof loader>();

  return (
    <>
      {authenticated && !isAuthPath ? (
        <APIProvider apiKey={config?.googleMapsApiKey || ""}>
          <SidebarProvider defaultOpen={sidebarDefaultOpen}>
            <SidebarApp />
            <SidebarInset>
              <Outlet />
            </SidebarInset>
          </SidebarProvider>
        </APIProvider>
      ) : (
        <Outlet />
      )}
    </>
  );
}

export const ErrorBoundary = ErrorBoundaryScreen;

export default ClerkApp(App);
