import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@kubrick/ui/components/ui/avatar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@kubrick/ui/components/ui/collapsible";
import {
  BriefcaseIcon,
  Building,
  ChevronRight,
  Gauge,
  LinkIcon,
  Settings,
  User,
} from "@kubrick/ui/components/ui/icons";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@kubrick/ui/components/ui/sidebar";
import { NavLink, useLocation } from "@remix-run/react";

import { Skeleton } from "@kubrick/ui/components/ui/skeleton";

const links = [
  {
    href: "/campaigns",
    label: "Campaigns",
    icon: BriefcaseIcon,
  },
  {
    href: "/performance",
    label: "Performance",
    icon: Gauge,
  },
  {
    href: "/settings",
    label: "Settings",
    icon: Settings,
    subItems: [
      {
        href: "/settings/business",
        label: "Business Profile",
        icon: Building,
      },
      {
        href: "/settings/integrations",
        label: "Integrations",
        icon: LinkIcon,
      },
    ],
  },
];

export const SidebarApp = () => {
  const location = useLocation();

  const isLinkActive = (href: string) => {
    return (
      location.pathname === href || location.pathname.startsWith(`${href}/`)
    );
  };

  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <NavLink to="/" className="flex items-center gap-2">
              {({ isActive }) => (
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={isActive}
                  className="transition-[radius] ease-linear group-data-[collapsible=icon]:rounded-none"
                >
                  <div className="flex items-center gap-2">
                    <div className="flex aspect-square size-8 items-center justify-center text-primary-foreground">
                      <Avatar className="size-8 rounded-none">
                        <AvatarImage src="/dgm_logo_navy.png" alt="The Guru" />
                        <AvatarFallback className="rounded-none">
                          <Skeleton className="size-8" />
                        </AvatarFallback>
                      </Avatar>
                    </div>
                    <span className="text-lg font-bold">the guru</span>
                  </div>
                </SidebarMenuButton>
              )}
            </NavLink>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {links.map((link) => (
              <SidebarMenuItem key={link.href}>
                {link.subItems ? (
                  <Collapsible
                    defaultOpen={isLinkActive(link.href)}
                    className="group/collapsible"
                  >
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton
                        tooltip={link.label}
                        isActive={isLinkActive(link.href)}
                      >
                        <link.icon className="h-4 w-4" />
                        <span>{link.label}</span>
                        <ChevronRight className="ml-auto h-4 w-4 transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      </SidebarMenuButton>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {link.subItems.map((subItem) => (
                          <SidebarMenuSubItem key={subItem.href}>
                            <NavLink to={subItem.href}>
                              {({ isActive }) => (
                                <SidebarMenuSubButton
                                  asChild
                                  isActive={isActive}
                                >
                                  <div className="flex items-center gap-2">
                                    <subItem.icon className="h-4 w-4" />
                                    <span>{subItem.label}</span>
                                  </div>
                                </SidebarMenuSubButton>
                              )}
                            </NavLink>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </Collapsible>
                ) : (
                  <NavLink to={link.href}>
                    {({ isActive }) => (
                      <SidebarMenuButton
                        isActive={isActive}
                        tooltip={link.label}
                      >
                        <link.icon className="h-4 w-4" />
                        <span>{link.label}</span>
                      </SidebarMenuButton>
                    )}
                  </NavLink>
                )}
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="mt-auto">
        <SidebarMenu>
          <SidebarMenuItem>
            <NavLink to="/profile">
              {({ isActive }) => (
                <SidebarMenuButton isActive={isActive}>
                  <User className="h-4 w-4" />
                  <span>Profile</span>
                </SidebarMenuButton>
              )}
            </NavLink>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
};
